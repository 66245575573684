
.ReactTags__tags {
    position: relative;
}
  
.ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
}
  
/* Styles for the input */
.ReactTags__tagInput {
    border-radius: 1rem;
    display: inline-block;
    
    text-overflow: ellipsis;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    border-radius: 4px; 
    margin: 0 0 0.5rem 0;
    
    padding: 4px 11px;

    font-size: 1rem;
    border: 1px solid #eee;
    min-width: 300px;
    line-height: 1.5rem;
}
  
.ReactTags__editInput {
    border-radius: 1px;
}
  
.ReactTags__editTagInput {
    display: inline-flex;
}
  
/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;

    background: #1177d1;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 0.2rem;
}

.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}
  
/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
}

.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}

.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}
  
.ReactTags__remove {
    font-size: 1rem;
    line-height: 0.5rem;

    margin-left: 0.5rem;
    border: none;
    cursor: pointer;
    background: none;
    color: #dddddd;
}